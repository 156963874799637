<template>
  <div id="join-us">
    <HeadSections
      class="join-img"
      :TitlePage="$route.name"
      :TitleSec="this.$i18n.locale == 'ar' ?`معاً نحو شراكة ناجحة`:`Together towards a successful partnership`"
      :PathPageFrom="$t('Home')"
      :PathPageTo="$t('JoinUs')"
      :pathFrom="`/`"
    />
    <div class="container" style=" overflow: hidden;margin-top: -100px;">
      <IndexJoinUs/>

    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'JoinUs Page',
  components: {
    HeadSections: defineAsyncComponent(() => import('@/components/Global/HeadSections.vue')),
    IndexJoinUs: defineAsyncComponent(() => import('@/components/JoinUs/IndexJoinUs.vue')),
  },
  data () {
    return {
      titleAr: 'عن منصة إتمام !',
      titleEn: 'About Etmaam Company !',
      title:'',
      description:'',
      img:'',
      card_1_title:'',
      card_1_description:'',
      card_1_photo:'',
      card_2_title:'',
      card_2_description:'',
      card_2_photo:'',
      card_3_title:'',
      card_3_description:'',
      card_3_photo:'',
    }
    },
    methods: {
    handleStaticAbout(StaticContent){
    StaticContent.forEach((item) => {
    if (item.key == 'about_etmaam') {
      this.title = item.value;
    }
    if(item.key == 'about_etmaam_description'){
      this.description = item.value;
    }
    if(item.key == 'about_etmaam_photo'){
      this.img = item.value;
    }
    if(item.key == 'card_1_title'){
      this.card_1_title = item.value;
    }
    if(item.key == 'card_1_description'){
      this.card_1_description = item.value;
    }
    if(item.key == 'card_1_photo'){
      this.card_1_photo = item.value;
    }
    if(item.key == 'card_2_title'){
      this.card_2_title = item.value;
    }
    if(item.key == 'card_2_description'){
      this.card_2_description = item.value;
    }
    if(item.key == 'card_2_photo'){
      this.card_2_photo = item.value;
    }
    if(item.key == 'card_3_title'){
      this.card_3_title = item.value;
    }
    if(item.key == 'card_3_description'){
      this.card_3_description = item.value;
    }
    if(item.key == 'card_3_photo'){
      this.card_3_photo = item.value;
    }
  });
    },


  mounted(){
    this.$store.dispatch('GetStaticContent').then(r => {
          this.handleStaticAbout(this.$store.state.StaticContent);
    });
  }
}
}

</script>




